import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import procure from '../../assets/img/procure.png';
import foodcognics from '../../assets/img/food.png';
import portfolio from '../../assets/img/portfolio.png';
import cointrans from '../../assets/img/Cointrans.png';
import PrimeIT from '../../assets/img/PrimeIT.png';
import ERP from '../../assets/img/ERP.png';
import Premier from '../../assets/img/Premier.png';
import masster from '../../assets/img/procuremasster.png';
import iora from '../../assets/img/iora.png';
import dance from '../../assets/img/dance.png';
import './HomeCarousel.css';

function HomeCarousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [headingText, setHeadingText] = useState('Procurement Model');
    const [paragraphText, setParagraphText] = useState('The procurement model is a web application model that combines both Spring and Angular technologies. Its tailored to serve the needs of both organizations and farmers.This model streamlines the process of acquiring goods or services, catering to the requirements of diverse stakeholders involved in procurement operations.');

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
        updateText(selectedIndex);
    };

    const updateText = (index) => {
        const headingTexts = [
            'Procurement Model',
            'Foodcognics Website',
            'Portfolio Website',
            'Cointrans Application',
            'PrimeIT Academy Website',
            'Premier Capital Advisor Website',
            'ERP Implementation',
            'Procure Masster Website',
            'Om Dance Academy Website',
            'Iora LifeCare Website'
        ];

        const paragraphTexts = [
            'The procurement model is a web application model that combines both Spring and Angular technologies. Its tailored to serve the needs of both organizations and farmers.This model streamlines the process of acquiring goods or services, catering to the requirements of diverse stakeholders involved in procurement operations.',
            'FoodCognics website, powered by Angular technology, offers a range of services catering to the diverse needs of the food industry. With a focus on meat, poultry, spices, and condiments, FoodCognics provides a comprehensive solution to address the complexities of the modern food landscape. Our platform includes informative blogs and resources covering various aspects of the food category we serve.At Foodcognics, we embrace a holistic approach, providing a 12-month handholding program specifically designed for aspiring food startups. This program offers personalized guidance, taking entrepreneurs from the conceptualization stage to the realization of their food business goals.',
            'Agrifoodbusinessexpert.com is a portfolio website showcasing the remarkable achievements and visionary approach of Vijay Kumar Chole, a prominent figure in Agricultural Economics.Through his groundbreaking contributions, Vijay Kumar Chole has revolutionized the agricultural sector, reshaping the landscape of Indian agriculture.The website, developed using Angular, provides insights into his innovative strategies and profound impact on the industry.',
            'The Cointrans application, built with Spring Boot for the backend and Angular for the frontend, serves as a versatile platform for retrieving coin data from various APIs.It dynamically presents this information on the screen, updating at hourly intervals.Users have the flexibility to select, update, and delete coin details, enhancing their control and interaction with the displayed data.',
            'At PrimeIT, we are transforming the future of technology education with our innovative approach! As leaders in IT training, we are celebrated for our results-oriented methods. Our steadfast dedication ensures exceptional training quality and fosters a forward-thinking mindset in our students. With PrimeIT, success is guaranteed. We offer a variety of courses in both placement and training models, ensuring our learners achieve their career goals. Our platform, PrimeItSkill, utilizes Angular for its frontend to deliver an outstanding user experience.',
            'Premier Capital Advisors, provides a premier level of Merger and Acquisition advisory services to individuals, families and companies. We utilize proven, unique, innovative processes to help our clients attain maximum value, while maintaining confidentiality and minimizing business disruption.',
            'Welcome to TechCognics India, your trusted partner for cutting-edge ERP (Enterprise Resource Planning) implementation solutions. Our dedicated team of experts specializes in delivering seamless and efficient ERP solutions that empower organizations to streamline operations, enhance productivity, and achieve success in the digital landscape.',
            `At Procure Masster, procurement isn't just a process—it's a strategic asset. With over two decades of experience in strategic sourcing, vendor development, and logistics management, I have crafted a vision to transform procurement into a value-creating function.The web-based procurement portal I developed is designed to elevate procurement activities to new heights of efficiency, transparency, and excellence. Whether you're a buyer or supplier, the platform offers robust tools for eAuctions, new vendor onboarding, and more. Discover how procurement can be transformed from a cost center into a profit center.`,
            `Om Dance Academy, founded by Omkar Shinde, excels in dance training, fitness, and skill enhancement. With expert faculty and innovative methods, the academy nurtures talent and pushes artistic boundaries. Anubhav, the annual showcase, celebrates creativity and inclusivity, offering students a platform to shine with mesmerizing performances and captivating choreography.`,
            `Iora's team of professionals brings over two decades of expertise in the international and domestic pharmaceutical markets. This deep industry knowledge ensures our service is fast, accurate, and effective. Our extensive network of national and international partners allows us to deliver high-quality products that comply with international regulations. At Iora, we prioritize strong relationships with both internal and external customers to ensure reliable service. Our transparent operations set us apart, enabling us to guarantee fair pricing for all.`,
        ];

        setHeadingText(headingTexts[index]);
        setParagraphText(paragraphTexts[index]);
    };

    return (
        <div className="carousel_comp">
            <div className="container caro-title" data-aos="fade-up">
                <h2>Experience Seamless Viewing Across Devices</h2>
            </div>
            <div className="container">
                <div className="row gy-3">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
                        <Carousel activeIndex={activeIndex} onSelect={handleSelect} fade>
                            <Carousel.Item>
                                <img src={procure} alt="First slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={foodcognics} alt="second slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={portfolio} alt="Third slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={cointrans} alt="fourth slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={PrimeIT} alt="fifth slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={Premier} alt="sixth slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={ERP} alt="seventh slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={masster} alt="seventh slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={dance} alt="seventh slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={iora} alt="seventh slide" className="d-block w-100 h-100" />
                            </Carousel.Item>
                        </Carousel>
                    </div>

                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-left" data-aos-delay="200">
                        <div className="about-content ps-0 ps-lg-3">
                            <h3 style={{ color: '#01bf8c' }}>{headingText}</h3>
                            <p className="fst-italic text-white">
                                {paragraphText}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeCarousel;
