import React, { useState } from "react";
import "./faq.css";
import faq from '../assets/img/faq.png';

function Faq() {
  const [activeItem, setActiveItem] = useState(null);
  const toggleFaq = (index) => {
    setActiveItem(index === activeItem ? null : index);
  };
  const faqData = [
    {
      question: "What services does Techcognics India offer?",
      answer: `Techcognics India offers a comprehensive range of web development services, including custom website design, responsive web development, e-commerce solutions, mobile app development, and website maintenance.`,
    },
    {
      question: "Can Techcognics India help improve my website's SEO?",
      answer: `Absolutely! We offer comprehensive SEO services designed to improve your website’s visibility, including on-page optimization, keyword research, content strategy, and technical SEO improvements.`,
    },
    {
      question: "What technologies do you use for web development?",
      answer: `Techcognics India uses the latest web development technologies, including HTML5, CSS3, JavaScript, PHP, Python, and popular frameworks like React, Angular, and Laravel, ensuring your website is modern, scalable, and efficient.`,
    },
    {
      question: "Can you develop custom software solutions for my business?",
      answer: `Yes, we provide custom software development services tailored to your business needs, whether it's a custom web application, mobile app, or enterprise software solution.`
    },
    {
      question: "What makes Techcognics India different from other web development companies?",
      answer: `Techcognics India stands out due to our commitment to delivering high-quality, custom-tailored solutions, our transparent communication, and our focus on long-term client satisfaction and support.`
    },
  ];
  return (
    <section id="faq-home" className="home-faq section-bg">
      <div className="container">
        <div className="web-title">
          <h1>Frequently Asked Questions</h1>
        </div>

        <div className="row">
          <div className="col-lg-5 d-flex justify-content-center align-items-center">
            <img src={faq} alt="faq" className="img-fluid home-img" />
          </div>
          <div className="col-lg-7" data-aos="fade-up" data-aos-delay="200">
            <div className="faq-container">
              {faqData.map((item, index) => (
                <div
                  key={index}
                  className={`faq-item ${index === activeItem ? "faq-active" : ""}`}
                  onClick={() => toggleFaq(index)}
                >
                  <h3>
                    <span className="num">{index + 1}.</span>{" "}
                    <span className="text-left">{item.question}</span>
                  </h3>
                  <div className="faq-content">
                    <p>{item.answer}</p>
                  </div>
                  <i className="faq-toggle bi bi-plus-circle-fill" style={{ fontSize: '22px' }}></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Faq;