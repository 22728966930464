import React from 'react';
import './Home.css'
import FITC from '../assets/FITC1.jpg';
import DelyBites from '../assets/delybites.png';
import FoodCognics from '../assets/client3.png';
import iora from '../assets/img/CLIENTS/iora1.png';
import dance from '../assets/clients/dance.jpeg';
import procure from '../assets/img/homeClients/pro.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

function Testimonial() {
  const testimonialsData = [
    {
      id: 1,
      author: "Neha Rajendra Tak",
      company: "FITC",
      image: FITC,
      content: "Working with TechCognics India Pvt. Ltd. exceeded our expectations and truly stands out in the competitive landscape. Their team's expertise and commitment to delivering a tailored ERP solution streamlined our business processes, enhancing efficiency across departments. The robust software has significantly contributed to our growth. Highly recommended for their professionalism and innovative solutions.",
    },
    {
      id: 2,
      author: "",
      company: "DelyBites Foods",
      image: DelyBites,
      content: "We are thankful to TechCognics for helping our online presence skyrocket. Their strategic approach to digital advertising not only increased our brand visibility but also generated a substantial uptick in leads. The targeted campaigns were a game-changer, delivering measurable results. Trustworthy, creative, and ROI-focused – we're thrilled with the impact on our business. We highly recommend TechCognics' services to anyone seeking reliable and innovative software development partners.",
    },
    {
      id: 3,
      author: "",
      company: "FoodCognics India Private Limited",
      image: FoodCognics,
      content: "We had an amazing experience working with TechCognics. Their team demonstrated extraordinary abilities, meticulous attention to detail, and dedication to producing excellent outcomes. Collaboration was seamless, and the final result accurately showcases their skills. We wholeheartedly endorse their services to anyone looking for a creative and dependable web development partner.",
    },
    {
      id: 4,
      author: "",
      company: "Iora Lifecare Private Limited",
      image: iora,
      content: `We at Iora Lifecare are incredibly grateful to Techcognics India for their exceptional work in developing our website. Their expertise and dedication have provided us with a platform that truly reflects our commitment to excellence in the pharmaceutical industry. Thanks to their efforts, we can now showcase our services with greater clarity and reach, ensuring that our clients and partners experience the best of what Iora has to offer. Techcognics India's professionalism and attention to detail have made all the difference, and we highly recommend their services.`,
    },
    {
      id: 5,
      author: "",
      company: "OM Dance Academy",
      image: dance,
      content: `Om Dance Academy extends heartfelt thanks to Techcognics India for their outstanding work in creating our website. Their expertise and creative vision have provided us with a platform that perfectly captures the spirit of our academy. Thanks to Techcognics, we can now showcase our programs and our annual showcase, Anubhav, with the professionalism and flair they deserve. Their attention to detail and commitment to excellence have truly made a difference, and we highly recommend their services.`
    },
    {
      id: 6,
      author: "",
      company: "Procure Masster",
      image: procure,
      content: `Procure Masster extends our deepest gratitude to Techcognics India for their exceptional work in developing our web-based procurement portal. Their expertise has enabled us to bring our vision of transforming procurement into a value-creating function to life. Thanks to Techcognics, we now have a platform that enhances efficiency, transparency, and excellence in our procurement activities. Their dedication and attention to detail have been invaluable, and we highly recommend their services.`
    }
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div>
      <div className="row">
        <div data-aos="fade-up"
          className="col-lg-12 text-center appear-animation animated appear-animation-visible"
          data-appear-animation="fadeInUpShorter">
          <h1 className="uppercase test_text">
            Client Testimonials
          </h1>
        </div>
      </div>
      <div
        id="testimonial_section"
        className="custom-section-padding staff-augmentation-testimonial">
        <div className="container">
          <div className="row">
            <div className="col">
              <Slider {...settings}>
                {testimonialsData.map((testimonial) => (
                  <div key={testimonial.id}>
                    <div className="row">
                      <div className="col-8 col-sm-4 col-lg-2 text-center pt-5 image">
                        <img
                          className="img-fluid"
                          src={testimonial.image}
                          width="166"
                          height="160"
                          alt="testimonial_img"
                          loading="lazy"
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-lg-10">
                        <div className="testimonial custom-testimonial-style-1 mb-0">
                          <ImQuotesLeft className='quotesImg' />
                          <blockquote className="pb-2">
                            <p>{testimonial.content}</p>
                          </blockquote>
                          <ImQuotesRight className='quotes' />
                          <div className="testimonial-author float-start">
                            <p>
                              <strong>{testimonial.author} - </strong>
                              <strong><span className="text-color-primary">
                                {testimonial.company}
                              </span></strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial;