import React from 'react'
import './Customers.css'
import img1 from '../../assets/client1.png'
import img2 from '../../assets/client2.jpg'
import img3 from '../../assets/client3.png'
import img4 from '../../assets/client4.jpg'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";

function Customers() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const clientImages = [
    "27.png",
    "mallows.png",
    "pro.png",
    "ioraClient.png",
    "9.png",
    "10.png",
    "omdance.png",
  ];

  return (
    <section id="home-client" className="home-client">
      <div className="container-fluid">
        <Slider {...settings}>
          {clientImages.map((image, index) => (
            <div key={index} className="client-logo">
              <img
                src={require(`../../assets/img/homeClients/${image}`)}
                className="img-fluid"
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Customers