import Button from 'react-bootstrap/Button';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom';
import './popUpButton.css';

function PopUpModal1() {
    return (
        <div>
            <Link to="/contact"
                as={Link}>
                <Button className='popUp'>
                    SEND ENQUIRY
                </Button>
            </Link>
        </div>
    )
}

export default PopUpModal1;