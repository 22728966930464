import React, { useEffect, useState } from "react";
import './TechComp.css';
import Tech from "./Tech";
import img1 from "../../assets/TECH-IMG/cloud.jpg";
import img2 from "../../assets/TECH-IMG/API.jpg";
import img3 from "../../assets/TECH-IMG/networking.jpg";
import img4 from "../../assets/TECH-IMG/MOBILE.jpg";
import img5 from "../../assets/TECH-IMG/data-store.jpg";
import img6 from "../../assets/TECH-IMG/analytics.jpg";

function TechComp() {
  const [showTech, setShowTech] = useState(false);

  useEffect(() => {
    const techDelay = setTimeout(() => {
      setShowTech(true);
    }, 500);

    return () => clearTimeout(techDelay);
  }, []);

  const techData = [
    { img: img6, title: "Data Analytics", desc: "You can reach our team at any time, day or night.", delay: 0 },
    { img: img3, title: "Networks", desc: "We have a team of highly qualified experts who specialize in designing", delay: 300 },
    { img: img5, title: "Data Storage", desc: "The data needs to be secured and at the same time making sure", delay: 600 },
    { img: img4, title: "Mobile Applications", desc: "The mobile apps have made life very easy for us.", delay: 900 },
    { img: img1, title: "Computing", desc: "Cloud Solutions center provides information on current hiring trends", delay: 1200 },
    { img: img2, title: "APIs", desc: "API is intermediary software which allows two different applications", delay: 1500 },

  ];

  const rows = [];
  for (let i = 0; i < techData.length; i += 3) {
    rows.push(techData.slice(i, i + 3));
  }

  return (
    <div className="tech_home">
      <div className="heading_title" data-aos="fade-right">
        <span>
          <h1><b>TECHNOLOGIES</b></h1>
        </span>
        <p className="paragraph">
          At TechCognics India Private Limited, we are deeply passionate about
          web and backend development. With a strong focus on the latest
          technologies, including Angular, React, Node.js, Java, Spring, Spring
          Boot, and Python, we are well-prepared to transform your digital
          concepts into reality. Our dedication spans both front-end and
          back-end development, ensuring your projects are not only visually
          captivating but also technically robust. We are committed to delivering
          top-tier web and backend solutions that precisely match your unique
          requirements.
        </p>
      </div>
      <div className="Techno_Comp">
        {showTech &&
          rows.map((row, rowIndex) => (
            <div className="row tech_home_row" key={rowIndex}>
              {row.map((tech, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-4 tech_home_comp"
                  data-aos="zoom-in-up"
                  data-aos-delay={tech.delay}
                >
                  <Tech img={tech.img} title={tech.title} desc={tech.desc} />
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export default TechComp;
