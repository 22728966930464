import React, { useState, useEffect } from "react";
import "./Header.css";
import techcognics from "../assets/techcognics_logo.png";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, Offcanvas, NavDropdown } from "react-bootstrap";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import web from '../assets/navIcons/webicon.png';
import app from '../assets/navIcons/appnew2.png';
import erp from '../assets/navIcons/ERPNEW.png';
import hire from '../assets/navIcons/icons8-hire-me-48.png';
import { SiFacebook } from "react-icons/si";

const Header = () => {
  const location = useLocation();
  const [showNavbar, setShowNavbar] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('home');

  useEffect(() => {
    const exeRoutes = [
      "/website-development",
      "/mobile-app-development-company",
      "/ERP-implementation-services",
      "/website-development/thankyou",
      "/mobile-app-development-company/thankyou",
      "/ERP-implementation-services/thankyou",
      "/outsourcing-web-development",
      "/outsourcing-web-development/thankyou",
      "/web-design-development",
      "/web-design-development/thankyou",
      "/web-development-company",
      "/web-development-company/thankyou"

    ];

    setShowNavbar(!exeRoutes.includes(location.pathname));
  }, [location]);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleNavItemClick = (menuItem) => {
    setShowOffcanvas(false);
    setActiveMenuItem(menuItem);
  };

  return (
    <>
      {["lg"].map((expand) => (
        <div className="header" key={expand}>
          <Navbar
            collapseOnSelect
            expand={expand}
            className="bg-body-tertiary w-100 header"
          >
            <Container fluid>
              <div className="logo">
                <Navbar.Brand
                  to="/"
                  as={Link}
                  className="option_link"
                  onClick={() => { handleNavItemClick('home'); scrollToTop(); }}
                >
                  <img className="header_logo" src={techcognics} alt="logo" />
                </Navbar.Brand>
              </div>


              {[
                "/website-development",
                "/website-development/thankyou",
              ].includes(location.pathname) && (
                  <div className="d-flex align-items-end">
                    <h3>Website Development</h3>
                  </div>
                )}
              {[
                "/mobile-app-development-company",
                "/mobile-app-development-company/thankyou",
              ].includes(location.pathname) && (
                  <div className="d-flex align-items-end">
                    <h3>Application Development</h3>
                  </div>
                )}
              {[
                "/ERP-implementation-services",
                "/ERP-implementation-services/thankyou",
              ].includes(location.pathname) && (
                  <div className="d-flex align-items-end">
                    <h3>ERP Implementation</h3>
                  </div>
                )}
              {["/outsourcing-web-development", "/outsourcing-web-development/thankyou"].includes(
                location.pathname
              ) && (
                  <div className="d-flex align-items-end">
                    <h3>Hire Our Team</h3>
                  </div>
                )}
              {[
                "/web-design-development",
                "/web-design-development/thankyou",
              ].includes(location.pathname) && (
                  <div className="d-flex align-items-end">
                  </div>
                )}
              {[
                "/web-development-company",
                "/web-development-company/thankyou",
              ].includes(location.pathname) && (
                  <div className="d-flex align-items-end">
                    <h3>Web Development</h3>
                  </div>
                )}
              {showNavbar && (
                <div className="nav ">
                  <Navbar.Toggle onClick={() => setShowOffcanvas(!showOffcanvas)} />
                  <Navbar.Offcanvas placement="end" className="w-50" show={showOffcanvas} onHide={() => setShowOffcanvas(false)}>
                    <Offcanvas.Header closeButton onHide={() => setShowOffcanvas(false)}>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1 pe-3 header_option" >
                        <Nav.Link
                          to="/"
                          as={Link}
                          onClick={() => { handleNavItemClick('home'); scrollToTop(); }}
                          className={`menu5 ${activeMenuItem === 'home' ? 'active' : ''}`}
                        >
                          <Nav.Item className="option menus">HOME</Nav.Item>
                        </Nav.Link>
                      </Nav>
                      <Nav className="justify-content-end flex-grow-1 pe-3 header_option">
                        <Nav.Link
                          to="/about"
                          as={Link}
                          onClick={() => { handleNavItemClick('about'); scrollToTop(); }}
                          className={`menu5 ${activeMenuItem === 'about' ? 'active' : ''}`}
                        >
                          <Nav.Item className="option menus">ABOUT US</Nav.Item>
                        </Nav.Link>
                      </Nav>
                      <Nav
                        className="justify-content-end title pe-3 header_option"
                        id="navbar">
                        <NavDropdown
                          title="SERVICES"
                          id="basic-nav-dropdown"
                          className="option text-center mx-auto menu5"
                          show={showDropdown}
                          onMouseEnter={() => setShowDropdown(true)}
                          onMouseLeave={() => setShowDropdown(false)}
                        >
                          <div className="nav-dropdown-container-grid grid grid-cols-1 md:grid-cols-4 gap-3 sm:flex flex-col ml-4">
                            <NavDropdown.Item
                              as={Link}
                              to="websitedevelopment"
                              onClick={() => { handleNavItemClick(); scrollToTop(); }}
                              className="animation_header grid-item md:w-1/4 transition duration-500 ease-in-out hover:bg-gray-500"
                            >
                              <div className="small_div flex flex items-start">
                                <img src={web} alt="Web_Development" className="mr-2" />
                                <div>
                                  <h2 className="text-sm font-medium">Web Development</h2>
                                  <p className="text-xs text-gray-500 md:block hidden-on-tablet hover:text-black">
                                    Create a websites or web
                                    <br />
                                    app & boost your presence.
                                  </p>
                                  <Link className="text-gray-500 text-xs hidden md:block hidden-on-tablet"
                                    to="websitedevelopment">
                                    <i>Read more</i> <i className="bi bi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="mobileapp-development-company"
                              onClick={() => { handleNavItemClick(); scrollToTop(); }}
                              className="animation_header grid-item md:w-1/4 transition duration-500 ease-in-out hover:bg-gray-500"
                            >
                              <div className="small_div flex flex items-start">
                                <img src={app} alt="mobileapp_Development" className="mr-2" />
                                <div>
                                  <h2 className="text-sm font-medium">App Development</h2>
                                  <p className="text-xs block text-gray-500 hidden md:block hidden-on-tablet hover:text-black">
                                    customized software to  <br />achieve
                                    business objectives.
                                  </p>
                                  <Link className="text-gray-500 text-xs hidden md:block hidden-on-tablet" to="mobileapp-development-company">
                                    <i>Read more</i> <i className="bi bi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="ERPimplementation-services"
                              onClick={() => { handleNavItemClick(); scrollToTop(); }}
                              className="animation_header grid-item md:w-1/4 transition duration-500 ease-in-out hover:bg-gray-500"
                            >
                              <div className="small_div flex flex items-start">
                                <img src={erp} alt="ERPimplementation" className="mr-2" />
                                <div>
                                  <h2 className="text-sm font-medium">ERP Implementation</h2>
                                  <p className="text-xs block text-gray-500 md:block hidden-on-tablet hover:text-black">
                                    Just one software to manage <br />all aspects of a business.
                                  </p>
                                  <Link className="text-gray-500 text-xs hidden md:block hidden-on-tablet"
                                    to="ERPimplementation-services">
                                    <i>Read more</i> <i className="bi bi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={Link}
                              to="outsourcingweb-development"
                              onClick={() => { handleNavItemClick(); scrollToTop(); }}
                              className="animation_header grid-item md:w-1/4 transition duration-500 ease-in-out hover:bg-gray-500">
                              <div className="small_div flex flex items-start">
                                <img src={hire} alt="outsourcingweb" className="mr-2" />
                                <div>
                                  <h2 className="text-sm font-medium">Hire Our Team</h2>
                                  <p className="text-xs block text-gray-500 md:block hidden-on-tablet hover:text-black">
                                    Turn your vision into reality <br />with
                                    our expert team.
                                  </p>
                                  <Link className="text-gray-500 text-xs hidden md:block hidden-on-tablet"
                                    to="outsourcingweb-development">
                                    <i>Read more</i> <i className="bi bi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </NavDropdown.Item>
                          </div>
                        </NavDropdown>
                      </Nav>
                      <Nav className="justify-content-end flex-grow-1 pe-3 header_option">
                        <Nav.Link
                          to="/technologies"
                          as={Link}
                          onClick={() => { handleNavItemClick('technologies'); scrollToTop(); }}
                          className={`menu5 ${activeMenuItem === 'technologies' ? 'active' : ''}`}
                        >
                          <Nav.Item className="option menus">TECHNOLOGIES</Nav.Item>
                        </Nav.Link>
                      </Nav>
                      <Nav className="justify-content-end flex-grow-1 pe-3 header_option">
                        <Nav.Link
                          to="/blog"
                          as={Link}
                          onClick={() => { handleNavItemClick('blog'); scrollToTop(); }}
                          className={`menu5 ${activeMenuItem === 'blog' ? 'active' : ''}`}
                        >
                          <Nav.Item className="option menus">BLOG</Nav.Item>
                        </Nav.Link>
                      </Nav>
                      <Nav className="justify-content-end flex-grow-1 pe-3 header_option">
                        <Nav.Link to="/contact" as={Link} onClick={() => { handleNavItemClick('contact'); scrollToTop(); }}
                          className={`menu5 ${activeMenuItem === 'contact' ? 'active' : ''}`}>
                          <Nav.Item className="option menus">CONTACT US</Nav.Item>
                        </Nav.Link>
                      </Nav>
                      <Nav className="justify-content-end flex-grow-1 pe-3 header_option">
                        <Nav.Link to="/career" as={Link} onClick={() => { handleNavItemClick('career'); scrollToTop(); }}
                          className={`menu5 ${activeMenuItem === 'career' ? 'active' : ''}`}>
                          <Nav.Item className="option menus">CAREER</Nav.Item>
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>

                  <div className="social">
                    <ul className="icon d-flex ">
                      <div className="social-icon-item" style={{ marginLeft: '-28px' }}>
                        <li className="item grow">
                          <a
                            href="https://www.facebook.com/profile.php?id=61550502891553&mibextid=ZbWKwL"
                            target="_blank"
                            rel="noreferrer"
                            className="social-facebook"
                          >
                            <SiFacebook style={{ color: "#316FF6" }} />
                          </a>
                        </li>
                      </div>
                      <li className="item grow">
                        <a
                          href="https://twitter.com/techcognics?t=sRI6Phs6n239snUg0gTAyg&s=09"
                          target="_blank"
                          rel="noreferrer"
                          className="social-twitter"
                        >
                          <FaTwitter style={{ color: "#1DA1F2" }} />
                        </a>
                      </li>
                      <li className="item grow">
                        <a
                          href="https://www.linkedin.com/company/techcognics-pvt-ltd/"
                          target="_blank"
                          rel="noreferrer"
                          className="social-linkedin"
                        >
                          <FaLinkedin style={{ color: "#0762C8" }} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </Container>
          </Navbar>
        </div>
      ))}
    </>
  );
};

export default Header;
