/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import "./Footer.css";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link, useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

function Footer() {
    const location = useLocation();
    useEffect(() => {
        Aos.init(
            {
                once: false,
                offset: 100,
                duration: 400,
                mirror: false,
            },
            []
        );
    });

    const exeRoutes = [
        "/website-development",
        "/mobile-app-development-company",
        "/ERP-implementation-services",
        "/outsourcing-web-development",
        "/web-design-development",
    ];

    const exeForConstactss = [
        "/web-design-development",
        "/outsourcing-web-development",
        "/ERP-implementation-services",
        "/website-development",
        "/mobile-app-development-company",
    ];

    const isExcludedPath = (path) => exeRoutes.includes(path);
    const showNavbarContactDetails = exeForConstactss.includes(location.pathname);

    return (
        <>
            <div className="footer">
                <div className="row5" data-aos="fade-up" data-aos-duration="1000">
                    <h4>Technologies</h4>
                    <span>React</span>
                    <span>Angular</span>
                    <span>Devops</span>
                    <span>Java</span>
                    <span>SQL Services</span>
                    <span>MongoDB</span>
                    <span>Python</span>
                    <span>Spring</span>
                    <span>JPA</span>
                </div>
                <div className="row2" data-aos="fade-up" data-aos-duration="1000">
                    <h4>Our Services</h4>
                    <Link to="/websitedevelopment">
                        <span>Web Application</span>
                    </Link>
                    <Link to="/mobileapp-development-company">
                        <span>Mobile Application</span>
                    </Link>
                    <Link to="/web-design-development">
                        <span>High Performance Website Designing</span>
                    </Link>
                    <Link to="/outsourcingweb-development">
                        <span>Third Party Outsourcing</span>
                    </Link>
                    <Link to="/ERPimplementation-services">
                        <span>ERP Implementation</span>
                    </Link>
                    <Link to="/services">
                        <span>IT Consultancy</span>
                    </Link>
                </div>
                <div className="row3" data-aos="fade-up" data-aos-duration="1000">
                    <h4>Company</h4>
                    <Link to="/about">
                        <span>About Us</span>
                    </Link>
                    <Link to="/technologies">
                        <span>Technology</span>
                    </Link>
                    <Link to="/consulting">
                        <span>Consulting</span>
                    </Link>
                    <Link to="/client">
                        <span>Clients</span>
                    </Link>
                    <Link to="/contact">
                        <span>Contact</span>
                    </Link>
                </div>
                <div className="row4" data-aos="fade-up" data-aos-duration="1000">
                    <h4>TechCognics</h4>
                    <span>Regus - Pune, WTC</span>
                    <span>Unit No 801</span>
                    <span>8th Floor, World Trade Center</span>
                    <span>Office No 848</span>
                    <span>EON Free Zone, Kharadi, Pune </span>
                    <span>Maharashtra - 411014 </span>
                    {!showNavbarContactDetails && (
                        <>
                            <span>
                                <a
                                    href="tel:9890452720"
                                    target="_blank"
                                    className="option_link hover:text-cyan-300"
                                    rel="noreferrer"
                                >
                                    <strong className="contact_detail">Phone: </strong>
                                    <span> +91 9890452720</span>
                                </a>
                            </span>
                            <span>
                                <a
                                    href="mailto:sales@techcognicsindia.com"
                                    target="_blank"
                                    className="option_link hover:text-cyan-300"
                                    rel="noreferrer"
                                >
                                    <strong className="contact_detail">Mail: </strong>
                                    <span> - sales@techcognicsindia.com</span>
                                </a>
                            </span>
                        </>
                    )}

                    <div className="handles" data-aos="flip-up" data-aos-duration="1000">
                        <h5>Follow Us</h5>
                        <div className="handle">
                            <a href={isExcludedPath(window.location.pathname) ? null: "https://www.facebook.com/profile.php?id=61550502891553&mibextid=ZbWKwL"}
                                target="_blank"
                                rel="noreferrer">
                                <FacebookRoundedIcon
                                    fontSize="medium"
                                    className="grow"
                                    style={{
                                        background: "#2b87c7",
                                        borderRadius: "50%",
                                        color: "#fff",
                                        marginRight: "8px",
                                    }}
                                />
                            </a>
                            <a
                                href={
                                    isExcludedPath(window.location.pathname)
                                        ? null
                                        : "https://twitter.com/techcognics?t=sRI6Phs6n239snUg0gTAyg&s=09"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <TwitterIcon
                                    fontSize="medium"
                                    className="grow"
                                    style={{
                                        background: "#38c0dd",
                                        borderRadius: "50%",
                                        color: "#fff",
                                        marginRight: "8px",
                                    }}
                                />
                            </a>
                            <a
                                href={
                                    isExcludedPath(window.location.pathname)
                                        ? null
                                        : "mailto:sales@techcognicsindia.com"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <GoogleIcon
                                    fontSize="medium"
                                    className="grow"
                                    style={{
                                        background: "#dc6218",
                                        borderRadius: "50%",
                                        color: "#fff",
                                        marginRight: "8px",
                                    }}
                                />
                            </a>
                            <a
                                href={
                                    isExcludedPath(window.location.pathname)
                                        ? null
                                        : "https://www.linkedin.com/company/techcognics-pvt-ltd"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LinkedInIcon
                                    fontSize="medium"
                                    className="grow"
                                    style={{
                                        background: "#2862d8",
                                        borderRadius: "50%",
                                        color: "#fff",
                                        marginRight: "8px",
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_bottom">
                <div className="first_half">
                    Copyright © 2023
                    <a href="https://techcognicsindia.com/" className="hover:text-cyan-200">techcongnicsindia.com</a>- All
                    rights reserved.
                </div>
                <div className="second_half">
                    <Link to="/privacy-policy" as={Link}>
                        <span className="hover:text-cyan-200">Terms and Conditions</span>
                    </Link>
                    |
                    <Link to="/privacy-policy" as={Link}>
                        <span className="hover:text-cyan-200">Privacy Policy</span>
                    </Link>
                    |<Link to="/contact">
                    <span className="hover:text-cyan-200">Site Map</span></Link>
                </div>
            </div>
        </>
    );
}

export default Footer;
