import React from 'react';
import './Tech.css';

function Tech({ img, title, desc, delay }) {
  return (
    <div className='technoService'>
      <div className="techno_component">
        <img className='tech_images' src={img} alt="TechImg" />
        <span className='tech_title_1'>{title}</span>
        <span className='description_tech'>{desc}</span>
      </div>
    </div>
  );
}

export default Tech;
